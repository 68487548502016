<template>
  <v-data-table
    :headers="headers"
    :items="builds"
    :loading="isLoading"
    :multi-sort="true"
    :server-items-length="builds.length"
    :sort-by.sync="computedSortBy"
    :sort-desc.sync="computedSortDesc"
    hide-default-footer
    loading-text="Loading data"
    item-key="_id"
    dense
  >
    <template v-slot:item.slots.0="item">
      <span v-if="item.value">
        {{ getPartShortname(item.value) }}
      </span>
    </template>
    <template v-slot:item.slots.1="item">
      <span v-if="item.value">
        {{ getPartShortname(item.value) }}
      </span>
    </template>
    <template v-slot:item.slots.2="item">
      <span v-if="item.value">
        {{ getPartShortname(item.value) }}
      </span>
    </template>
    <template v-slot:item.slots.3="item">
      <span v-if="item.value">
        {{ getPartShortname(item.value) }}
      </span>
    </template>
    <template v-slot:item.time="item">
        <span v-if="item.value" style="white-space: nowrap">
          {{ item.value.days }}d
          {{ item.value.hours }}h
          {{ item.value.minutes }}m
        </span>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SubmarineCalculatorBuildsList',
  data() {
    return {
      sortKey: null,
      sortBy: [],
      sortDesc: [],
      parts: [],
      headers: [
        {
          text: 'Rank',
          value: 'rank',
          align: 'center',
        },
        {
          text: 'Cost',
          value: 'components',
          align: 'center',
        },
        {
          text: 'Repair',
          value: 'repairCost',
          align: 'center',
        },
        {
          text: 'Hull',
          value: 'slots.0',
          align: 'center',
        },
        {
          text: 'Stern',
          value: 'slots.1',
          align: 'center',
        },
        {
          text: 'Bow',
          value: 'slots.2',
          align: 'center',
        },
        {
          text: 'Bridge',
          value: 'slots.3',
          align: 'center',
        },
        {
          text: 'Surveillance',
          value: 'stats.surveillance',
          align: 'center',
        },
        {
          text: 'Retrieval',
          value: 'stats.retrieval',
          align: 'center',
        },
        {
          text: 'Speed',
          value: 'stats.speed',
          align: 'center',
        },
        {
          text: 'Range',
          value: 'stats.range',
          align: 'center',
        },
        {
          text: 'Favor',
          value: 'stats.favor',
          align: 'center',
        },
        {
          text: 'Time',
          value: 'time',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.parts = (await this.$http.get('/submarine/parts'))?.data?.results;
  },
  computed: {
    ...mapGetters('submarinesCalculator', [
      'builds',
      'isLoading',
    ]),
    computedSortBy: {
      get() {
        return this.$store.state.submarinesCalculator.sorting.sortBy;
      },
      set(value) {
        this.$store.commit('submarinesCalculator/SET_SORTING_SORTBY', value);
        this.$store.dispatch('submarinesCalculator/updateSorting', {
          type: 'sortBy',
          data: value,
        });
        this.fetchBuilds();
      },
    },
    computedSortDesc: {
      get() {
        return this.$store.state.submarinesCalculator.sorting.sortDesc;
      },
      set(value) {
        this.$store.commit('submarinesCalculator/SET_SORTING_SORTDESC', value);
        this.$store.dispatch('submarinesCalculator/updateSorting', {
          type: 'sortDesc',
          data: value,
        });
        this.fetchBuilds();
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.sort();
      },
      deep: true,
    },
    sortBy() {
      this.sort();
    },
    sortDesc() {
      this.sort();
    },
  },
  methods: {
    ...mapActions('submarinesCalculator', [
      'fetchBuilds',
    ]),
    getPartShortname(partId) {
      return this.parts.filter((p) => p.id === partId)[0]?.shortname_en;
    },
    resetSorting() {
      this.sortingPriority = [{ field: 'stats.speed', order: 'desc' }];
    },
    sort() {
      const sort = [];

      for (const [key, value] of Object.entries(this.sortBy)) {
        sort.push(`${value},${this.sortDesc[key] ? 'desc' : 'asc'}`);
      }
    },
    sortingPriorityRemoved(field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field,
      );
    },
    sortPressed(field, order, event) {
      if ((this.sortKey && event[this.sortKey]) || !this.sortKey) {
        const index = this.sortingPriority.findIndex((i) => i.field === field);
        const newPriority = this.sortingPriority[index];
        if (newPriority) {
          newPriority.order = newPriority.order === 'desc' ? 'asc' : 'desc';
          const currentSortingPriority = this.sortingPriority.slice();
          currentSortingPriority[index] = newPriority;
          this.sortingPriority = currentSortingPriority;
        } else {
          // request sorted data from backend
          this.sortingPriority = [...this.sortingPriority.slice(), { field, order }];
        }
      } else {
        // request regular sorted data from backend
        this.sortingPriority = [{ field, order }]; // [{field, order}]
      }
    },
  },
};
</script>

<style scoped>

</style>
