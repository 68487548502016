<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="data"
    :search="search"
    disable-pagination
    hide-default-footer
    loading-text="Loading data"
    item-key="_id"
    dense
  >
    <template v-slot:header.breakpoints.surveillance="header">
      <div>
        {{ header.header.text }}
      </div>
      <div>
        Mid | High
      </div>
    </template>
    <template v-slot:header.breakpoints.retrieval="header">
      <div>
        {{ header.header.text }}
      </div>
      <div>
        Norm | Optim
      </div>
    </template>
    <template v-slot:item.breakpoints.surveillance="item">
      <span v-if="item.value">
        {{item.value.mid}} |
        {{item.value.high}}
      </span>
    </template>
    <template v-slot:item.breakpoints.retrieval="item">
      <span v-if="item.value">
        {{item.value.norm}} |
        {{item.value.optim}}
      </span>
    </template>
    <template v-slot:item.expReward="item">
      {{ getExpString(item.value) }}
    </template>
    <template v-slot:item.unlockedBy="item">
      {{ getSectorNameById(item.value) }}
    </template>
    <template v-slot:item.unlocks.slot="item">
      {{ item.value }}
    </template>
    <template v-slot:item.unlocks.sectors="item">
      {{ getListSectorUnlock(item.value) }}
    </template>
  </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SubmarineMap',
  props: [
    'map',
  ],
  data() {
    return {
      search: '',
      data: [],
      headers: [
        {
          text: 'ID',
          value: 'lettername_en',
          align: 'center',
        },
        {
          text: 'Rank',
          value: 'rank',
          align: 'center',
        },
        {
          text: 'Name',
          value: 'name_en',
          align: 'center',
        },
        {
          text: 'Unlocked by',
          value: 'unlockedBy',
          align: 'center',
        },
        {
          text: 'Experience',
          value: 'expReward',
          align: 'center',
        },
        {
          text: 'Surveillance',
          value: 'breakpoints.surveillance',
          align: 'center',
        },
        {
          text: 'Retrieval',
          value: 'breakpoints.retrieval',
          align: 'center',
        },
        {
          text: 'Favor',
          value: 'breakpoints.favor',
          align: 'center',
        },
        {
          text: 'Sub #',
          value: 'unlocks.slot',
          align: 'center',
        },
        {
          text: 'Unlocks sectors',
          value: 'unlocks.sectors',
          align: 'center',
        },
      ],
      sectors: [],
    };
  },
  mounted() {
    this.$http.get('/submarine/sectors')
      .then((response) => {
        this.sectors = response.data?.results.filter((sector) => sector.isExplorable);
        this.data = this.sectors.filter((sector) => sector.mapId === this.map);
      });
  },
  methods: {
    getSectorNameById(id) {
      return this.sectors.filter((s) => s.id === id)[0]?.name_en;
    },
    getListSectorUnlock(ids) {
      return ids?.map((id) => this.sectors.filter((s) => s.id === id)[0]?.name_en).join(', ');
    },
    getExpString(exp) {
      return exp?.toLocaleString();
    },
  },
};
</script>
<style scoped>
  tr, td, table { border: 1px solid black; border-collapse: collapse; border-spacing: unset;
    padding: 5px; }
</style>
