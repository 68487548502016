<template>
  <v-container fluid>
    <v-row no-gutters dense class="my-0 py-0">
      <template v-for="sector of options">
        <v-col :key="sector._id" cols="12" xs="12" sm="6" md="3" lg="2" xl="2" class="py-0 my-0"
          style="padding: 5px;">
          <v-checkbox v-model="selection"
                      dense
                      hide-details
                      :value="sector"
          >
            <template v-slot:label>
                <span class="text-caption">
                  (<b>{{ sector.lettername_en }}</b>) {{ sector.name_en }}
                </span>
            </template>
          </v-checkbox>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn @click="reset">Reset the selection</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SubmarineCalculatorSectorsCalculator
          :selectedSectors="selection"
          :sectorsData="sectors"
        ></SubmarineCalculatorSectorsCalculator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubmarineCalculatorSectorsCalculator from '@/modules/submarines/calculator/SubmarineCalculatorSectorsCalculator.vue';

export default {
  name: 'SubmarineCalculatorSectors',
  components: { SubmarineCalculatorSectorsCalculator },
  props: [
    'map',
  ],
  data() {
    return {
      sectors: [],
      selection: [],
    };
  },
  computed: {
    options() {
      return this.sectors && this.sectors.filter((s) => s.isExplorable);
    },
  },
  mounted() {
    this.$http.get('/submarine/sectors', { params: { map_id: this.map.id } }).then((res) => {
      this.sectors = res.data?.results;
    });
  },
  methods: {
    select() {
      this.$emit('change', { selection: this.selection, sectors: this.sectors });
    },
    reset() {
      this.selection = [];
    },
  },
};
</script>

<style scoped>

</style>
