<template>
  <v-col class="text-center pt-0">
    <v-btn class="mx-1" @click="resetStats">Reset the stats</v-btn>
    <v-btn class="mx-1" @click="resetSorting">Reset the sorting</v-btn>
    <v-btn class="mx-1" color="primary" @click="search">Search</v-btn>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SubmarineCalculatorCurrentDetails',
  data() {
    return {
      noLimitOnResults: false,
    };
  },
  computed: {
    ...mapGetters('submarinesCalculator', [
      'builds',
    ]),
  },
  methods: {
    ...mapActions('submarinesCalculator', [
      'fetchBuilds',
    ]),
    search() {
      this.$store.commit('submarinesCalculator/SET_NO_LIMIT_ON_RESULTS', this.noLimitOnResults);
      this.fetchBuilds();
    },
    resetStats() {
      this.$store.commit('submarinesCalculator/RESET_STATS');
    },
    resetSorting() {
      this.$store.commit(
        'submarinesCalculator/SET_SORTING_SORTBY',
        ['stats.speed'],
      );
      this.$store.commit(
        'submarinesCalculator/SET_SORTING_SORTDESC',
        [true],
      );
      this.fetchBuilds();
    },
  },
};
</script>

<style scoped>

</style>
