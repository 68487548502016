import { render, staticRenderFns } from "./SubmarineCalculatorBuildsList.vue?vue&type=template&id=0baeaf3c&scoped=true&"
import script from "./SubmarineCalculatorBuildsList.vue?vue&type=script&lang=js&"
export * from "./SubmarineCalculatorBuildsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0baeaf3c",
  null
  
)

export default component.exports