<template>
  <section>
    <SubmarineCalculatorStatFields label="Rank"
                                   v-model="computedRank"></SubmarineCalculatorStatFields>
    <SubmarineCalculatorStatFields label="Surveillance"
                                   v-model="computedSurveillance"></SubmarineCalculatorStatFields>
    <SubmarineCalculatorStatFields label="Retrieval"
                                   v-model="computedRetrieval"></SubmarineCalculatorStatFields>
    <SubmarineCalculatorStatFields label="Speed"
                                   v-model="computedSpeed"></SubmarineCalculatorStatFields>
    <SubmarineCalculatorStatFields label="Range"
                                   v-model="computedRange"></SubmarineCalculatorStatFields>
    <SubmarineCalculatorStatFields label="Favor"
                                   v-model="computedFavor"></SubmarineCalculatorStatFields>
  </section>
</template>

<script>
import SubmarineCalculatorStatFields from '@/modules/submarines/calculator/SubmarineCalculatorStatFields.vue';

export default {
  name: 'SubmarineCalculatorStatsFilters',
  components: { SubmarineCalculatorStatFields },
  computed: {
    computedRank: {
      get() {
        return this.$store.state.submarinesCalculator.stats.rank;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_RANK_STAT', newValue);
      },
    },
    computedSurveillance: {
      get() {
        return this.$store.state.submarinesCalculator.stats.surveillance;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_SURVEILLANCE_STAT', newValue);
      },
    },
    computedRetrieval: {
      get() {
        return this.$store.state.submarinesCalculator.stats.retrieval;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_RETRIEVAL_STAT', newValue);
      },
    },
    computedSpeed: {
      get() {
        return this.$store.state.submarinesCalculator.stats.speed;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_SPEED_STAT', newValue);
      },
    },
    computedRange: {
      get() {
        return this.$store.state.submarinesCalculator.stats.range;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_RANGE_STAT', newValue);
      },
    },
    computedFavor: {
      get() {
        return this.$store.state.submarinesCalculator.stats.favor;
      },
      set(newValue) {
        this.$store.commit('submarinesCalculator/SET_FAVOR_STAT', newValue);
      },
    },
  },
};
</script>

<style scoped>
</style>
