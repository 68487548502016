<template>
  <v-container fluid>
    <v-snackbar
      v-model="snackbarErrorShow"
      :timeout="snackbarErrorTimeout"
      multi-line
      color="red dark"
    >
      {{ snackbarErrorHeaderText }}<br/>
      {{ snackbarErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbarErrorShow = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="1">
        <v-text-field
          v-model="rank"
          type="number"
          label="Rank"
          min="1"
          max="120"
          :rules="[ () => rank > 0 || 'Rank must be greater than 0' ]"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="currentSpeed"
          type="number"
          label="Speed"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click="update" color="primary">
          Search
        </v-btn>

        <v-btn
          @click="share"
          class="ml-5"
        >
          Get link
        </v-btn>
        <v-snackbar
          v-model="snackbar"
          :timeout="2000"
        >
          Link copied!
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col cols="2">
        <v-combobox
          v-model="selectedHull"
          :items="hullList"
          label="Hull"
          item-text="shortname_en"
          dense
          auto-select-first
        >
          <template v-slot:selection="row">
            {{ row.item.shortname_en }}
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="2">
        <v-combobox
          v-model="selectedStern"
          :items="sternList"
          label="Stern"
          item-text="shortname_en"
          dense
          auto-select-first
        >
          <template v-slot:selection="row">
            {{ row.item.shortname_en }}
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="2">
        <v-combobox
          v-model="selectedBow"
          :items="bowList"
          label="Bow"
          item-text="shortname_en"
          dense
          auto-select-first
        >
          <template v-slot:selection="row">
            {{ row.item.shortname_en }}
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="2">
        <v-combobox
          v-model="selectedBridge"
          :items="bridgeList"
          label="Bridge"
          item-text="shortname_en"
          dense
          auto-select-first
        >
          <template v-slot:selection="row">
            {{ row.item.shortname_en }}
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="currentExp"
          type="number"
          label="Current xp"
        ></v-text-field>
        {{ getCurrentExp.toLocaleString() }} / {{ getRank }}
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="useExpFilter"
          label="Find paths for the remaining exp"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-text-field
          v-model="days"
          type="number"
          label="Days"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="hours"
          type="number"
          label="Hours"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="minutes"
          type="number"
          label="Minutes"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="useDesiredTime"
          label="Use desired time"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="onlyShowLetters"
          label="Only show sector's letter instead of fullname"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-combobox
          v-model="includeSectors"
          :items="sectorsList"
          label="Must include sectors"
          multiple
          chips
          item-text="name_en"
          :deletable-chips="true"
          small-chips
          :search-input.sync="searchIncludeSectors"
          @change="searchIncludeSectors = ''"
          clearable
          auto-select-first
        >
          <template v-slot:selection="row">
            <v-chip
              :key="JSON.stringify(row.item)"
              v-bind="row.attrs"
              :input-value="row.selected"
              :disabled="row.disabled"
              small
              close
              @click:close="row.parent.selectItem(row.item)"
            >
              <v-avatar
                class="accent white--text"
                left
                v-text="row.item.lettername_en"
              ></v-avatar>
              {{ row.item.name_en }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="5">
        <v-combobox
          v-model="excludeSectors"
          :items="sectorsList"
          label="Must exclude sectors"
          multiple
          chips
          item-text="name_en"
          :deletable-chips="true"
          small-chips
          :search-input.sync="searchExcludeSectors"
          @change="searchExcludeSectors = ''"
          clearable
          auto-select-first
        >
          <template v-slot:selection="row">
            <v-chip
              :key="JSON.stringify(row.item)"
              v-bind="row.attrs"
              :input-value="row.selected"
              :disabled="row.disabled"
              small
              close
              @click:close="row.parent.selectItem(row.item)"
            >
              <v-avatar
                class="accent white--text"
                left
                v-text="row.item.lettername_en"
              ></v-avatar>
              {{ row.item.name_en }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-combobox
          v-model="includeMaps"
          :items="mapsList"
          label="Must include maps"
          multiple
          chips
          item-text="Name"
          :deletable-chips="true"
          small-chips
          :search-input.sync="searchIncludeMaps"
          @change="searchIncludeMaps = ''"
          clearable
          auto-select-first
        ></v-combobox>
      </v-col>
      <v-col cols="5">
        <v-combobox
          v-model="excludeMaps"
          :items="mapsList"
          label="Must exclude maps"
          multiple
          chips
          item-text="Name"
          :deletable-chips="true"
          small-chips
          :search-input.sync="searchExcludeMaps"
          @change="searchExcludeMaps = ''"
          clearable
          auto-select-first
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
     Current range: {{ currentRange }}
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="isLoading"
          :multi-sort="true"
          :options.sync="options"
          loading-text="Loading data"
          disable-pagination
          hide-default-footer
          dense
          item-key="_id"
          class="elevation-1"
        >
          <template v-slot:item.Exp="row">
            {{ row.item.Exp.toLocaleString() }}
          </template>
          <template v-slot:item.Efficiency="row">
            {{ row.item.ExpPerMinute }}
          </template>
          <template v-slot:item.MapID="row">
            ({{ row.item.MapID }}) {{ mapsList[row.item.MapID - 1].Name }}
          </template>
          <template v-slot:item.RouteDetails="row">
            <v-breadcrumbs class="my-0 py-0 " :items="toSectorNameArray(row.item.Route)">
              <template v-slot:divider>
                <v-icon>mdi-arrow-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item>
                  {{ item }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </template>
          <template v-slot:item.Distance="row">
            {{ getEstimatedTime(row.item.Distance) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubmarineCalculatorService from '@/services/submarine-calculator.service';

export default {
  name: 'SubmarineExpCalculator',
  data() {
    return {
      snackbarErrorShow: false,
      snackbarErrorTimeout: 5000,
      snackbarErrorHeaderText: '',
      snackbarErrorText: '',

      snackbar: false,
      isInitAlready: false,
      onlyShowLetters: false,
      options: {
        sortBy: ['ExpPerMinute'],
        sortDesc: [true],
      },
      data: [],
      rankList: [],
      hullList: [],
      sternList: [],
      bowList: [],
      bridgeList: [],
      selectedHull: null,
      selectedStern: null,
      selectedBow: null,
      selectedBridge: null,
      rank: 1,
      range: 70,
      speed: 110,
      currentExp: 0,
      useExpFilter: false,
      mapsList: [
        {
          ID: 1,
          Name: 'Deep-sea Site',
        },
        {
          ID: 2,
          Name: 'Sea of Ash',
        },
        {
          ID: 3,
          Name: 'Sea of Jade',
        },
        {
          ID: 4,
          Name: 'Sirensong Sea',
        },
        {
          ID: 5,
          Name: 'The Liliac Sea',
        },
      ],
      searchIncludeMaps: '',
      searchExcludeMaps: '',
      includeMaps: [],
      excludeMaps: [],
      sectorsList: [],
      searchIncludeSectors: '',
      searchExcludeSectors: '',
      includeSectors: [],
      excludeSectors: [],
      useDesiredTime: false,
      days: 0,
      hours: 0,
      minutes: 0,
      isLoading: true,
      headers: [
        {
          text: 'Rank',
          value: 'Rank',
          align: 'center',
        },
        {
          text: 'EXP',
          value: 'Exp',
          align: 'center',
        },
        {
          text: 'Time',
          value: 'Distance',
          align: 'center',
        },
        {
          text: 'EXP/Min',
          value: 'ExpPerMinute',
          align: 'center',
        },
        {
          text: 'Range',
          value: 'Range',
          align: 'center',
        },
        {
          text: 'Sectors #',
          value: 'SectorsTotal',
          align: 'center',
        },
        {
          text: 'Map',
          value: 'MapID',
          align: 'center',
        },
        {
          text: 'Sectors',
          value: 'RouteDetails',
          align: 'left',
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    await Promise.all([
      this.$http.get('/submarine/ranks'),
      this.$http.get('/submarine/parts'),
      this.$http.get('/submarine/sectors'),
    ]).then((promises) => {
      // Ranks promise
      this.rankList = promises[0].data?.results;

      // Parts promise
      const parts = promises[1].data?.results;
      this.hullList = parts.filter((part) => part.slot === 0);
      this.sternList = parts.filter((part) => part.slot === 1);
      this.bowList = parts.filter((part) => part.slot === 2);
      this.bridgeList = parts.filter((part) => part.slot === 3);
      this.selectedHull = this.hullList[0] || null;
      this.selectedStern = this.sternList[0] || null;
      this.selectedBow = this.bowList[0] || null;
      this.selectedBridge = this.bridgeList[0] || null;

      if (this.$route.query.rank) {
        this.rank = +this.$route.query.rank;
      }

      if (this.$route.query.slot0) {
        const part = this.hullList.filter((p) => p.id === +this.$route.query.slot0)[0] || null;
        if (part) {
          this.selectedHull = part;
        }
      }
      if (this.$route.query.slot1) {
        const part = this.sternList.filter((p) => p.id === +this.$route.query.slot1)[0] || null;
        if (part) {
          this.selectedStern = part;
        }
      }
      if (this.$route.query.slot2) {
        const part = this.bowList.filter((p) => p.id === +this.$route.query.slot2)[0] || null;
        if (part) {
          this.selectedBow = part;
        }
      }
      if (this.$route.query.slot3) {
        const part = this.bridgeList.filter((p) => p.id === +this.$route.query.slot3)[0] || null;
        if (part) {
          this.selectedBridge = part;
        }
      }

      // Sectors promise
      this.sectorsList = promises[2].data?.results.map((sector) => {
        if (!sector.isExplorable) {
          const header = this.mapsList.filter((m) => m.ID === sector.mapId)[0].Name;
          return { ...sector, header };
        }
        return sector;
      });
      if (this.$route.query.includeSectors) {
        const includeSectors = this.$route.query.includeSectors.split(',');
        if (includeSectors && includeSectors.length > 0) {
          const results = includeSectors.map(
            (sectorId) => this.sectorsList.filter((s) => s.id === +sectorId)[0] || null,
          ).filter((s) => s !== null);
          if (results.length > 0) {
            this.includeSectors = results;
          }
        }
      }
      if (this.$route.query.excludeSectors) {
        const excludeSectors = this.$route.query.excludeSectors.split(',');
        if (excludeSectors && excludeSectors.length > 0) {
          const results = excludeSectors.map(
            (sectorId) => this.sectorsList.filter((s) => s.id === +sectorId)[0] || null,
          ).filter((s) => s !== null);
          if (results.length > 0) {
            this.excludeSectors = results;
          }
        }
      }
      if (this.$route.query.includeMaps) {
        const includeMaps = this.$route.query.includeMaps.split(',');
        if (includeMaps && includeMaps.length > 0) {
          const results = includeMaps.map(
            (mapId) => this.mapsList.filter((s) => s.ID === +mapId)[0] || null,
          ).filter((s) => s !== null);
          if (results.length > 0) {
            this.includeMaps = results;
          }
        }
      }
      if (this.$route.query.excludeMaps) {
        const excludeMaps = this.$route.query.excludeMaps.split(',');
        if (excludeMaps && excludeMaps.length > 0) {
          const results = excludeMaps.map(
            (mapId) => this.mapsList.filter((s) => s.ID === +mapId)[0] || null,
          ).filter((s) => s !== null);
          if (results.length > 0) {
            this.excludeMaps = results;
          }
        }
      }

      // Done
      this.isInitAlready = true;
      this.update();
    });

    // this.$http.get('/submarine/exp-calculator', {
    //   params: {
    //     rank: this.rank,
    //     range: this.range,
    //   },
    // }).then((res) => {
    //   this.data = res.data?.collection;
    // }).finally(() => {
    //   this.isLoading = false;
    // });
  },
  computed: {
    currentRange() {
      let range = 0;
      const slots = ['Hull', 'Stern', 'Bow', 'Bridge'];
      for (const slot of slots) {
        if (this[`selected${slot}`]) {
          range += this[`selected${slot}`].range;
        }
      }
      const selectedRank = this.rank < 50 ? 50 : +this.rank;
      const rankRow = this.rankList.filter((rank) => rank.rank === selectedRank)[0];
      if (rankRow) {
        range += rankRow.range;
      }
      return range;
    },
    desiredDistance() {
      return Math.ceil((
        +this.days * 24 * 60 + +this.hours * 60 + +this.minutes + 5 - (12 * 60)
      ) * this.currentSpeed);
    },
    getCurrentExp() {
      return +this.currentExp.toLocaleString();
    },
    getRank() {
      return this.rankList[this.rank - 1]?.expToNext.toLocaleString();
    },
    currentSpeed() {
      let speed = 0;
      const slots = ['Hull', 'Stern', 'Bow', 'Bridge'];
      for (const slot of slots) {
        if (this[`selected${slot}`]) {
          speed += this[`selected${slot}`].speed;
        }
      }
      const selectedRank = this.rank < 50 ? 50 : +this.rank;
      const rankRow = this.rankList.filter((rank) => rank.rank === selectedRank)[0];
      if (rankRow) {
        speed += rankRow.speed;
      }
      // this.currentSpeed = speed;
      return speed;
    },
  },
  watch: {
    currentRange() {
      // let speed = 0;
      // const slots = ['Hull', 'Stern', 'Bow', 'Bridge'];
      // for (const slot of slots) {
      //   if (this[`selected${slot}`]) {
      //     speed += this[`selected${slot}`].speed;
      //   }
      // }
      // const selectedRank = this.rank < 50 ? 50 : +this.rank;
      // const rankRow = this.rankList.filter((rank) => rank.rank === selectedRank)[0];
      // if (rankRow) {
      //   speed += rankRow.speed;
      // }
      // this.currentSpeed = speed;
    },
    options: {
      handler() {
        if (this.isInitAlready) {
          this.update();
        }
      },
      deep: true,
    },
  },
  methods: {
    toSectorNameArray(route) {
      return route.map((sectorId) => {
        if (!this.onlyShowLetters) {
          return `(${this.sectorsList[sectorId]?.lettername_en}) ${this.sectorsList[sectorId]?.name_en}`;
        }
        return this.sectorsList[sectorId].lettername_en;
      });
    },
    getEstimatedTime(time) {
      if (!time || time < 1) {
        return 'N/A';
      }
      const estimatedTime = SubmarineCalculatorService.getTotalTime(time, this.currentSpeed);

      return `${estimatedTime.days}d ${estimatedTime.hours}h ${estimatedTime.minutes}m`;
    },
    share() {
      const baseUrl = `${document.location.protocol}//${document.location.host}${this.$route.path}`
        .replace(/\/$/, '');
      const query = {
        rank: this.rank,
        slot0: this.selectedHull.id,
        slot1: this.selectedStern.id,
        slot2: this.selectedBow.id,
        slot3: this.selectedBridge.id,
      };
      if (this.includeSectors.length > 0) {
        query.includeSectors = this.includeSectors.map((s) => s.id).join(',');
      }
      if (this.excludeSectors.length > 0) {
        query.excludeSectors = this.excludeSectors.map((s) => s.id).join(',');
      }
      if (this.includeMaps.length > 0) {
        query.includeMaps = this.includeMaps.map((m) => m.ID).join(',');
      }
      if (this.excludeMaps.length > 0) {
        query.excludeMaps = this.excludeMaps.map((m) => m.ID).join(',');
      }
      navigator.clipboard.writeText(`${baseUrl}?${Object.entries(query).map(([k, v]) => `${k}=${v}`).join('&')}`);
      this.snackbar = true;
    },
    update() {
      if (this.currentSpeed < 1) {
        this.snackbarErrorHeaderText = 'Error';
        this.snackbarErrorText = 'Speed must be greater than 0.';
        this.snackbarErrorShow = true;
        return;
      }
      if (this.rank < 1) {
        this.snackbarErrorHeaderText = 'Error';
        this.snackbarErrorText = 'Rank must be greater than 0.';
        this.snackbarErrorShow = true;
        return;
      }
      this.isLoading = true;
      const opts = {};
      if (this.useDesiredTime) {
        opts.maxDistance = this.desiredDistance;
      }
      if (this.includeSectors.length > 0) {
        this.includeSectors = [...this.includeSectors.filter((s) => s?.id > 0)];
        opts.includeSectors = this.includeSectors.filter((s) => s?.id > 0).map((sector) => sector.id).join(',');
      }
      if (this.excludeSectors.length > 0) {
        this.excludeSectors = [...this.excludeSectors.filter((s) => s?.id > 0)];
        opts.excludeSectors = this.excludeSectors.map((sector) => sector.id).join(',');
      }
      if (this.includeMaps.length > 0) {
        this.includeMaps = [...this.includeMaps.filter((s) => s?.ID > 0)];
        opts.includeMaps = this.includeMaps.map((m) => m.ID).join(',');
      }
      if (this.excludeMaps.length > 0) {
        this.excludeMaps = [...this.excludeMaps.filter((s) => s?.ID > 0)];
        opts.excludeMaps = this.excludeMaps.map((m) => m.ID).join(',');
      }

      if (this.useExpFilter) {
        opts.exp = this.rankList[this.rank - 1].expToNext - this.currentExp;
      }

      opts.speed = this.currentSpeed;

      const sort = this.options.sortBy.map((field, i) => `${field},${this.options.sortDesc[i] ? 'desc' : 'asc'}`);

      this.$http.get('/submarine/exp-calculator', {
        params: {
          rank: this.rank,
          range: this.currentRange,
          sort: sort.join(','),
          ...opts,
        },
      }).then((res) => {
        this.data = res.data?.collection;
      }).catch((ex) => {
        if (ex.response?.status >= 400) {
          this.snackbarErrorHeaderText = `[${ex.response.status}] Error`;
          this.snackbarErrorText = ex.response.data;
        } else {
          this.snackbarErrorHeaderText = 'Unexpected Error';
          this.snackbarErrorText = ex.message;
        }
        this.snackbarErrorShow = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
