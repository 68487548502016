<template>
  <v-container fluid class="mb-0 pb-0">
    <v-row no-gutters dense>
      <v-col cols="12">
        <div class="subtitle-1">Best route </div>
          <div v-if="bestRoute">
            <v-container fluid>
              <v-row dense no-gutters>
                <v-col>
                  <v-breadcrumbs :items="bestRoute.sectors" class="mx-0 px-0">
                    <template v-slot:divider>
                      <v-icon>mdi-arrow-right</v-icon>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-breadcrumbs-item>
                        {{ item.name_en }}
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </v-col>
              </v-row>
              <v-row dense no-gutters>
                <v-col cols="auto">
                  <v-text-field
                    v-model.number="currentSpeed"
                    :rules="currentSpeedRules"
                    type="number"
                    label="Speed"
                    class="mx-1 speed-field"
                    min="1"
                    outlined
                    dense
                  ></v-text-field>
                  <v-simple-table dense class="mx-0 px-0">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-center">Distance</th>
                        <th class="text-center">Time</th>
                        <th class="text-center">Fuel</th>
                        <th class="text-center">Exp</th>
                        <th class="text-center">Surveillance</th>
                        <th class="text-center">Retrieval</th>
                        <th class="text-center">Favor</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ bestRoute.distance }}</td>
                        <td>{{ getEstimatedTime(bestRoute.time) }}</td>
                        <td>{{ bestRoute.fuel }}</td>
                        <td>{{ currentExpFormatted }}</td>
                        <td>
                <span v-if="breakpoints.surveillance">
                  {{ breakpoints.surveillance.mid }} | {{ breakpoints.surveillance.high }}
                </span>
                        </td>
                        <td>
                <span v-if="breakpoints.retrieval">
                  {{ breakpoints.retrieval.norm }} | {{ breakpoints.retrieval.optim }}
                </span>
                        </td>
                        <td>
                <span v-if="breakpoints.favor">
                  {{ breakpoints.favor }}
                </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-else>
          <div class="body-2">No sector selected.</div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-select
        v-model="currentSelection"
        :items="routes"
        dense
        clearable
        hide-details
        label="Routes"
        outlined
        item-text="string"
        return-object
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs class="my-0 py-0 " :items="item.sectors">
            <template v-slot:divider>
              <v-icon>mdi-arrow-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item>
                {{ item.name_en }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs> | Distance: {{ item.distance }} | Time: {{ getEstimatedTime(item.time) }}
        </template>
        <template v-slot:selection="{ item }">
          <v-breadcrumbs class="my-0 py-0 " :items="item.sectors">
            <template v-slot:divider>
              <v-icon>mdi-arrow-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item>
                {{ item.name_en }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs> | Distance: {{ item.distance }} | Time: {{ getEstimatedTime(item.time) }}
        </template>
      </v-select>
    </v-row>
    <v-row no-gutters dense>
      <v-container class="text-center">
        <v-row dense no-gutters>
          <v-col class="text-center py-0 my-0">
            <v-btn color="primary"
                   :disabled="!this.currentSelection"
                   @click="setSelectedRoute">
              Select this route
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SubmarineCalculatorService from '@/services/submarine-calculator.service';

export default {
  name: 'SubmarineCalculatorSectorsCalculator',
  props: ['selectedSectors', 'sectorsData'],
  data() {
    return {
      currentExp: null,
      currentSpeed: 90,
      currentSpeedRules: [
        (v) => !!v || 'required',
        (v) => +v > 0 || 'must be > 1',
      ],
      currentSelection: null,
    };
  },
  computed: {
    ...mapGetters('submarinesCalculator', {
      selectedRoute: 'selectedRoute',
    }),
    currentExpFormatted() {
      return this.currentExp > 0 ? this.currentExp.toLocaleString() : null;
    },
    routes() {
      if (this.selectedSectors?.length === 0) {
        return [];
      }
      const routes = this.permutation(this.selectedSectors);

      const listRoutes = [];

      for (const r of routes.sort((a, b) => {
        const s1 = this.calculateDistanceAndRawTime(a);
        const s2 = this.calculateDistanceAndRawTime(b);
        /*
        if (aDistance < bDistance) {
          return -1;
        }
        if (aDistance > bDistance) {
          return 1;
        }
        return 0; */
        return s1.distance - s2.distance || s1.time - s2.time;
      })) {
        const value = this.calculateDistanceAndRawTime(r);
        listRoutes.push({
          string: r.map((s) => s.name_en).join(' -- '),
          sectors: r,
          distance: value.distance,
          time: value.time,
          fuel: r.map((s) => s.ceruleumTankReq).reduce((a, b) => a + b, 0),
        });
      }
      return listRoutes;
    },
    bestRoute() {
      return this.routes?.length > 0 ? this.routes[0] : null;
    },
    breakpoints() {
      if (!this.routes || this.routes.length === 0
        || this.routes[0].sectors.filter((s) => s.breakpoints).length === 0) {
        return {
          surveillance: null,
          retrieval: null,
          favor: null,
        };
      }
      const route = this.routes[0];

      const surveillanceList = route.sectors.map((s) => s.breakpoints?.surveillance);
      const retrievalList = route.sectors.map((s) => s.breakpoints?.retrieval);
      const favorList = route.sectors.map((s) => s.breakpoints?.favor);

      const midSurvey = Math.max(...surveillanceList.map((bp) => bp?.mid));
      const highSurvey = Math.max(...surveillanceList.map((bp) => bp?.high));

      const normRet = Math.max(...retrievalList.map((bp) => bp?.norm));
      const optimRet = Math.max(...retrievalList.map((bp) => bp?.optim));

      const favor = favorList.reduce((a, b) => Math.max(a, b));

      return {
        surveillance: { mid: midSurvey, high: highSurvey },
        retrieval: { norm: normRet, optim: optimRet },
        favor,
      };
    },
  },
  watch: {
    routes() {
      this.currentSelection = this.routes.length > 0 ? this.routes[0] : null;
      if (this.routes[0]?.sectors) {
        this.currentExp = this.routes[0]
          .sectors.map((a) => a.expReward).reduce((a, b) => a + b, 0);
      } else {
        this.currentExp = null;
      }
    },
    currentSpeed(value) {
      if (+value < 1) {
        this.currentSpeed = 1;
      }
    },
  },
  methods: {
    setSelectedRoute() {
      this.$store.commit('submarinesCalculator/SET_SELECTED_ROUTE', this.currentSelection);
      this.$store.commit('submarinesCalculator/SET_ROUTES', this.routes);
    },
    getEstimatedTime(time) {
      if (!time || time < 1) {
        return 'N/A';
      }
      const estimatedTime = SubmarineCalculatorService.getTotalTime(time, this.currentSpeed);

      return `${estimatedTime.days}d ${estimatedTime.hours}h ${estimatedTime.minutes}m`;
    },
    calculateDistanceAndRawTime(route) {
      let rawDistance = 0;
      let rawTime = 0;
      let currentPoint = 0;

      if (route[0].mapId === 2) {
        currentPoint = this.sectorsData.filter((s) => !s.isExplorable && s.mapId === 2)[0].id;
      } else if (route[0].mapId === 3) {
        currentPoint = this.sectorsData.filter((s) => !s.isExplorable && s.mapId === 3)[0].id;
      } else if (route[0].mapId === 4) {
        currentPoint = this.sectorsData.filter((s) => !s.isExplorable && s.mapId === 4)[0].id;
      } else if (route[0].mapId === 5) {
        currentPoint = this.sectorsData.filter((s) => !s.isExplorable && s.mapId === 5)[0].id;
      }

      route.forEach((destSector) => {
        const toDestination = this.sectorsData.filter((s) => s.id === currentPoint)[0]
          .toDestination[destSector.id];
        rawDistance += toDestination.range + destSector.surveyRange;
        rawTime += toDestination.distance + destSector.surveyDistance;
        currentPoint = destSector.id;
      });

      return {
        distance: rawDistance,
        time: rawTime,
      };
    },
    swap(array, pos1, pos2) {
      const temp = array[pos1];
      array[pos1] = array[pos2];
      array[pos2] = temp;
    },
    permutation(array, n, results = []) {
      n = n || array.length;
      if (n === 1) {
        results.push(array.slice());
      } else {
        for (let i = 1; i <= n; i += 1) {
          this.permutation(array, n - 1, results);
          let j;
          if (n % 2) {
            j = 1;
          } else {
            j = i;
          }
          this.swap(array, j - 1, n - 1);
        }
      }
      return results;
    },
  },
};
</script>

<style scoped>
  table tr > td { text-align: center; }
  table tr * { text-align: center; }
  /deep/ .speed-field { width: 90px; }
  /deep/ .speed-field input { text-align: center; }
</style>
