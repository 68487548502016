<template>
  <div>
    <div>{{ label }}</div>
    <v-container>
      <v-row dense no-gutters>
        <v-col>
          <v-text-field
            v-model.number="minValue"
            type="number"
            label="Min"
            class="mx-1"
            hide-details
            outlined
            dense
            @input="update"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="maxValue"
            type="number"
            label="Max"
            class="mx-1"
            hide-details
            outlined
            dense
            @input="update"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="exactValue"
            type="number"
            label="Exact"
            class="mx-1"
            hide-details
            outlined
            dense
            @input="update"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SubmarineCalculatorStatFields',
  props: ['label', 'value'],
  data() {
    return {
      min: this.value?.min ? this.value.min : null,
      max: this.value?.max ? this.value.max : null,
      exact: this.value?.exact ? this.value.exact : null,
    };
  },
  computed: {
    minValue: {
      get() {
        return this.min ? this.min : null;
      },
      set(newValue) {
        this.min = newValue;
        this.$emit('input', this.min);
      },
    },
    maxValue: {
      get() {
        return this.max ? this.max : null;
      },
      set(newValue) {
        this.max = newValue;
        this.$emit('input', this.max);
      },
    },
    exactValue: {
      get() {
        return this.exact ? this.exact : null;
      },
      set(newValue) {
        this.exact = newValue;
        this.$emit('input', this.exact);
      },
    },
  },
  watch: {
    value(newValue) {
      this.min = newValue.min;
      this.max = newValue.max;
      this.exact = newValue.exact;
    },
  },
  methods: {
    update() {
      this.$emit('input', {
        min: this.min,
        max: this.max,
        exact: this.exact,
      });
    },
  },
};
</script>

<style scoped>
</style>
