<template>
    <v-container fluid>
      <v-card>
          <v-tabs v-model="tab">
            <v-tab v-for="map in maps" :key="map.id">{{ map.name_en }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="map in maps" :key="map.id">
              <SubmarineMap
                v-bind:map="map.id"></SubmarineMap>
            </v-tab-item>
          </v-tabs-items>
      </v-card>
    </v-container>
</template>

<script>
import SubmarineMap from '../modules/submarines/maps/SubmarineMap.vue';

export default {
  name: 'SubmarineMaps',
  components: { SubmarineMap },
  data() {
    return {
      tab: null,
      maps: [],
    };
  },
  async mounted() {
    this.maps = (await this.$http.get('/submarine/maps'))?.data?.results;
  },
};
</script>

<style scoped>

</style>
